import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import { VscTriangleRight } from "react-icons/vsc";

const Glossar = ({ locale, url }) => {
  const data = useStaticQuery(
      graphql`
      query BlogpostsQuery {
        allStrapiBlogposts(sort: {order: DESC, fields: Date}) {
          edges {
            node {
              id
              Date(formatString: "DD.MM.YYYY", locale: "de")
              Title
              Preview
              locale
              slug
            }
          }
        }
      }
    `
  )

  const posts = data.allStrapiBlogposts.edges.map((e) => e.node).filter((i) => i.locale === locale)

  return <>
    {posts.map(post => (
      <div className="box">
        <h4>{post.Date}</h4><br/>
        <h2 className="title is-3">{post.Title}</h2>
        <p>{post.Preview}</p>

        <div className="blogpost-link">
          <Link to={`/${post.locale}/${post.slug}`}>
            Mehr erfahren
            <VscTriangleRight size={15} />
          </Link>
        </div>
      </div>
    ))}
  </>
};

export default Glossar;
