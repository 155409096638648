import React from 'react';
import {useStaticQuery, graphql} from "gatsby"
import FoldableList from "../foldableList"

const Biographies = ({ locale }) => {
  const data = useStaticQuery(graphql `
    query BiographiesQuery {
      allStrapiBiographies(sort: {order: ASC, fields: Name}) {
        edges {
          node {
            id
            locale
            Name
            Content
            Sources
            Images {
              id
              Caption
              Image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 800, placeholder: TRACED_SVG, webpOptions: {quality: 95})
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const items = data.allStrapiBiographies.edges.map((e) => e.node).filter((i) => i.locale === locale)

  return <FoldableList locale={locale} data={items} searchable />
};

export default Biographies;
