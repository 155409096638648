import React, { useState } from 'react';
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import '../styles/style.scss';

const Sources = ({ sources }) => {
  const clickHandler = () => { setActive(!active) }

  const [active, setActive] = useState(false);

  return <>
    {sources && sources !== '' &&
      <div className="sources mt-3" onClick={() => clickHandler()}>
        <h4 className="title is-4">
          Quellen
          <span className="ml-1">
            {active ? <VscTriangleUp size={15} /> : <VscTriangleDown size={15} />}
          </span>
        </h4>

        {active && <p>{sources}</p>}
      </div>}
  </>
}

export default Sources;
