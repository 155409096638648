import React, {useState} from 'react';
import Content from "./content"
import Sources from "./sources"
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import Fade from 'react-reveal/Fade';

import '../styles/style.scss';

const FoldableList = ({ url, locale, title, data, searchable, noGlossar }) => {

  const clickHandler = (id) => {
    id === activeItemId ? setActiveItemId(null) : setActiveItemId(id)
  }

  const [activeItemId, setActiveItemId] = useState();
  const [items, setItems] = useState(data);

  const handleInputChange = (event) => {
    const searchString = event.target.value;
    const filteredData = data.filter(item => {
      const title = item.Title || item.Name
      return item.Content.toLowerCase().includes(searchString.toLowerCase()) ||
        title.toLowerCase().includes(searchString.toLowerCase())
    })
    setItems(filteredData);
  }

  const active = (id) => {
    return id === activeItemId || (url && url.includes(id))
  }

  return <div className="mt-3 mb-6">
    {searchable && <input
      id="filter-input"
      className="input is-medium mb-4"
      type="text"
      aria-label="Search"
      placeholder="Suchen..."
      onChange={handleInputChange}
    />}

    {title && <h4 className="title is-4">{title}</h4>}

    {items
      .map((i) => {
        return <Fade>
          <div className="box list-box">
            <h4 onClick={() => clickHandler(i.id)} id={i.id} className="is-4 title">
              {i.Name || i.Title}
              <span className="ml-2">
                {active(i.id) ? <VscTriangleUp size={15} /> : <VscTriangleDown size={15} />}
              </span>
            </h4>
            {active(i.id) &&
              <>
                <Content locale={locale} markdown={i.Content} images={i.Images} noGlossar={noGlossar} />
                <Sources sources={i.Sources} />
              </>
            }
          </div>
          </Fade>
      })}
  </div>
};

export default FoldableList;
