import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import '../styles/style.scss';

// no variable src possible in gatsby static image component
const StaticImages = () => {
  return (
    <div className="columns is-mobile is-centered is-multiline static-images">
      <div className="column is-one-quarter-tablet is-half-mobile">
        <StaticImage src="../images/02 Aerial FINAL Corrected.jpeg" placeholder={"tracedSVG"} />
      </div>
      <div className="column is-one-quarter-tablet is-half-mobile">
        <StaticImage src="../images/Doppelseite-30-31-2.jpg" placeholder={"tracedSVG"} />
      </div>
      <div className="column is-one-quarter-tablet is-half-mobile">
        <StaticImage src="../images/P1160517.jpeg" placeholder={"tracedSVG"} />
      </div>
      <div className="column is-one-quarter-tablet is-half-mobile">
        <StaticImage src="../images/IMG_4727.jpeg" placeholder={"tracedSVG"} />
      </div>
    </div>
  )
}

export default StaticImages;
