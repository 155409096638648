import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import FoldableList from "../foldableList"

const Glossar = ({ locale, url }) => {
  const data = useStaticQuery(
    graphql`
      query GlossarsTitlesQuery {
        allStrapiGlossars(sort: {order: ASC, fields: Title}) {
          edges {
            node {
              id
              locale
              Title
              Content
            }
          }
        }
      }
    `
  )

  const items = data.allStrapiGlossars.edges.map((e) => e.node).filter((i) => i.locale === locale)

  return <FoldableList url={url} locale={locale} data={items} searchable noGlossar />
};

export default Glossar;
