import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Glossar from "../components/lists/glossar"
import Biographies from "../components/lists/biographies"
import Blogposts from "../components/lists/blogposts"
import FoldableList from "../components/foldableList"
import Sources from "../components/sources"
import Content from "../components/content"
import StaticImages from "../components/staticImages"

const Page = ({ location, data, pageContext }) => {
  const { page, subpages, locale, headerImage, parent } = pageContext

  const SubpageLinks = ({ classes }) => {
    if (!subpages || subpages.length === 0) return '';
    const elements = [parent || page, ...subpages];
    let colWidth = '';
    if (elements.length > 4) colWidth = 'is-one-quarter';
    if (elements.length===6) colWidth = 'is-one-third';

    return <>
      <div className={`columns is-centered is-multiline subpages-navbar ${classes}`}>
        {elements.map(( subpage ) => {
          return <div className={`column ${colWidth} is navbar-item-subpage`}>
            <Link
              key={subpage.slug}
              to={`/${page.locale}/${subpage.slug}`}
              activeClassName='active'
            >
              {subpage.Title}
            </Link>
          </div>
        })}
      </div>
      <div style={{ clear: 'both' }}></div>
    </>
  }

  return (
    <Layout locale={locale} isSubpage={!parent} headerImageData={headerImage.localFile.childImageSharp.gatsbyImageData}>
      <section className="section">
        {page.Title === 'Index' && <StaticImages filenames={['../images/background.png']} />}

        <SubpageLinks classes={'top'} />
        <div className='container pb-5'>
          <Content locale={locale} markdown={page.Content} images={page.Images} />

          {data.allStrapiLists.edges.map((edge) => {
            return <FoldableList locale={locale} title={edge.node.Title} data={edge.node.ListItems} />
          })}

          {page.slug.includes('glossar') && <Glossar url={location.href} locale={locale} />}
          {page.slug.includes('biogr') && <Biographies locale={locale} />}
          {page.slug.includes('aktuelles') && <Blogposts locale={locale} />}

          <Sources sources={page.Sources} />
        </div>

        <SubpageLinks classes={'is-hidden-desktop'} />
      </section>
    </Layout>
  )
}

export default Page;

export const query = graphql`
    query ListsQuery($lists: [String!]) {
      allStrapiLists(filter: {id: {in: $lists}}) {
        edges {
          node {
            id
            Title
            ListItems {
              id
              Title
              Sources
              Content
              Images {
                id
                Caption
                Image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 800, placeholder: TRACED_SVG, webpOptions: {quality: 95})
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
